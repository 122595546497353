<template>
  <b-card
    v-if="!loaded"
    :style="{'max-width': huayData.type === 1 ? '1024px' : '600px'}"
  >
    <b-card-text>
      <b-row
        class="justify-content-start align-items-center  text-center text-sm-left"
        no-gutters
      >
        <b-col
          sm="auto"
        >
          <span
            class="mr-1"
            style="font-size: 1.5rem"
          >กำหนดรอบ {{ huayData.name }}</span>
        </b-col>
        <b-col
          sm="auto"
          class="mt-50 mt-sm-0 text-center"
        >
          <b-button
            style="width: 200px;"
            pill
            :variant="statusTextColor[status]"
          >
            {{ statusText[status] }}
          </b-button>
        </b-col>
      </b-row>
    </b-card-text>

    <CreateRound
      v-if="status === 9"
      class="mt-2"
      :huay-data="huayData"
      @onSaveFinish="reload"
    />

    <SettingResult
      v-if="status > 0 && status !== 9"
      ref="resultComponent"
      :huay-data="huayData"
      class="mt-2 mb-2"
    />

    <UpdateRoundTime
      v-if="status < 4"
      class="mt-2 mb-2"
      :huay-data="huayData"
      @onSaveFinish="reload"
    />

    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="dark"
      class="mt-2 mb-50 mr-50"
      @click="$router.go(-1)"
    >
      <feather-icon
        icon="ChevronLeftIcon"
        class="mr-25"
      />
      <span>ย้อนกลับ</span>
    </b-button>
  </b-card>
</template>

<script>
import {
  BButton, BCard, BCardText, BRow, BCol,
} from 'bootstrap-vue'
import lottoService from '@/service/lottoService'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'

import CreateRound from '@/views/components/CreateRound.vue'
import SettingResult from '@/views/components/SettingResult.vue'
import UpdateRoundTime from '@/views/lotto/UpdateRoundTime.vue'

export default {
  components: {
    BCard,
    BCardText,
    BButton,
    BCol,
    BRow,
    CreateRound,
    UpdateRoundTime,
    SettingResult,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loaded: true,
      statusText: [
        'ยังไม่ถึงเวลาเเทง',
        'อยู่ระหว่างเปิดให้เเทง',
        'หยุดพักชั่วคราว',
        'หมดเวลาเเทง',
        'อยู่ระหว่างคำนวนเครดิต',
        'คำนวนเครดิตเรียบร้อยเเล้ว',
        'รอจ่ายเครดิต',
        'อยู่ระหว่างจ่ายเครดิต',
        'อยู่ระหว่างคืนโพย',
        'ปิดรอบเเทงเรียบร้อยเเล้ว',
      ],
      statusTextColor: [
        'cyan-7',
        'teal',
        'danger',
        'warning',
        'secondary',
        'dark',
        'warning',
        'secondary',
        'secondary',
        'info-2',
      ],
      startDate: '',
      endDate: '',
      huayData: {
        name: '',
        externalLink: '',
        raw: '',
      },
      endTime: null,
      interval: null,
    }
  },
  computed: {
    status() {
      return this.huayData.status
    },
  },
  destroyed() {
    if (this.interval) clearInterval(this.interval)
  },
  async mounted() {
    this.reload()
    // this.setupHub()
    const res = await lottoService.getAuthen('huay/servertime')
    if (res.status === 200) {
      this.$store.state.app.serverTime = new Date(
        res.data.serverTime * 1000,
      ).getTime()

      this.interval = setInterval(() => {
        const oldTime = new Date(this.$store.state.app.serverTime)
        oldTime.setSeconds(oldTime.getSeconds() + 1)
        this.$store.state.app.serverTime = oldTime.getTime()

        if (this.huayData.status === 1) {
          const difference = this.endTime.getTime() - this.$store.state.app.serverTime
          if (difference < 0) {
            this.reload()
          }
        }
      }, 1000)
    }

    setInterval(async () => {
      const { id } = this.$route.query
      if (id) {
        // eslint-disable-next-line no-shadow
        const res = await lottoService.getAuthen(`huay/id/${id}`)
        setTimeout(() => {
          if (res.status === 200) {
            // this.huayData = res.data
            this.huayData.status = res.data.status
            this.huayData.roundStartDate = res.data.roundStartDate
            this.huayData.roundEndDate = res.data.roundEndDate
            this.huayData.endTime = res.data.endTime
            this.endTime = new Date(this.huayData.endTime * 1000)
          }
        }, 100)
      }
    }, 5000)
  },
  methods: {
    async setupHub() {
      // this.$store.state.huayConnection = new signalR.HubConnectionBuilder()
      //   .withUrl(`${this.$store.state.huayHubUrl}/messages`)
      //   .build()
      //
      // await this.$store.state.huayConnection.start().then(() => {})
      //
      // this.$store.state.huayConnection.on(
      //   'onStateChange',
      //   async nextState => {
      //     if (nextState) {
      //       if (this.huayData.id === nextState.huayId
      //           && this.huayData.roundId === nextState.roundId) {
      //         this.huayData.status = nextState.status
      //         if (nextState.isCreated) {
      //           this.reload()
      //         }
      //       }
      //     }
      //   },
      // )
    },
    async reload() {
      this.loaded = true
      const { id } = this.$route.query
      if (id) {
        const res = await lottoService.getAuthen(`huay/id/${id}`)
        setTimeout(() => {
          if (res.status === 200) {
            this.huayData = res.data
            this.endTime = new Date(this.huayData.endTime * 1000)
          }
          this.loaded = false
        }, 100)
      }
    },
    async save() {
      const { id } = this.$route.query
      if (id) {
        this.$swal({
          dark: true,
          title: 'Are you sure?',
          text: 'คุณเเน่ใจว่าต้องการบันทึกข้อมูลใช่หรือไม่',
          icon: 'info',
          showCancelButton: true,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(async result => {
          if (result.value) {
            const res = await lottoService.putAuthen(`huay/id/${id}`, this.huayData)
            if (res.status === 200) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'success',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: 'บันทึกข้อมูสำเร็จ',
                },
              })
            } else {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Error',
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                  text: res.data,
                },
              })
            }
          }
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
